// import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
// import * as Sentry from '@sentry/sveltekit';

// Sentry.init({
//   dsn: 'https://65ff1410c69de927c85a4c9397ab73e3@o4504737804255232.ingest.sentry.io/4505932347080704',
//   tracesSampleRate: 1.0,

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,

//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,

//   // If you don't want to use Session Replay, just remove the line below:
//   integrations: [new Replay()],
// });

// // If you have a custom error handler, pass it to `handleErrorWithSentry`
// export const handleError = handleErrorWithSentry();

import { SentryDSN } from '$lib/sentry';
import * as Sentry from '@sentry/svelte';
import { BrowserTracing } from '@sentry/browser';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
	dsn: SentryDSN,
	tracesSampleRate: 1.0,
	integrations: [new BrowserTracing()],
	ignoreErrors: [
		/Not found\:.*wp-(includes|content).*/,
		/Not found\:.*\.aws\/.*/,
		/Not found\:.*\/plugins\/.*/,
		'Not found: /ads.txt',
		'Not found: /favicon.ico',
		'Not found: /robots.txt',
		'Not found: /apple-touch-icon.png',
		'Not found: /robots.txt'
	]
});

Sentry.setTag('svelteKit', 'server');

export const handleError: HandleClientError = ({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } });

	return {
		message: (error as App.Error).message
	};
};
